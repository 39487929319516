"use client";
import { Box, Flex, Grid, Text, Container, Anchor } from "@mantine/core";
import CoreButton from "@/components/core-components/core-button/CoreButton";
import LinkedInSvg from "../../../../public/assets/svgs/footerLogoSVG/linkedInSvg";
import InstagramSvg from "./../../../../public/assets/svgs/footerLogoSVG/instagramSvg";
import FacebookSvg from "../../../../public/assets/svgs/footerLogoSVG/facebookSvg";
import TwitterSvg from "../../../../public/assets/svgs/footerLogoSVG/twitterSvg";
import FooterLogo from "./../../../../public/assets/svgs/FooterLogo";
import { useFooterStyles } from "./style";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { classes } = useFooterStyles();

  return (
    <Box
      bg="background.2"
      px={{ lg: 80, md: 56, sm: 16 }}
      py={{ xs: 60, base: 20 }}
    >
      <Container size="xl">
        <Grid>
          <Grid.Col
            span={{ sm: 4, base: 12 }}
            order={{ sm: 1, base: 3 }}
            mt={{ sm: 0, base: 16 }}
          >
            <Flex
              direction="column"
              justify="center"
              align={{ sm: "start", base: "center" }}
              gap={{ md: 44, xs: 30, base: 16 }}
            >
              <Box w={{ md: "200px", base: "156px" }}>
                <FooterLogo />
              </Box>
              <Flex gap={24}>
                <Box
                  w={{ md: 32, base: 21 }}
                  component="a"
                  href="https://www.linkedin.com/company/vint-sltn"
                  target="__blank"
                  aria-label="LinkedIn"
                >
                  <LinkedInSvg />
                </Box>
                <Box
                  w={{ md: 32, base: 21 }}
                  component="a"
                  href="https://www.instagram.com/vintsltn/"
                  target="__blank"
                  aria-label="Instagram"
                >
                  <InstagramSvg />
                </Box>
                <Box
                  w={{ md: 32, base: 21 }}
                  component="a"
                  href="https://www.facebook.com/vintsltn"
                  target="__blank"
                  aria-label="Facebook"
                >
                  <FacebookSvg />
                </Box>
                <Box
                  w={{ md: 32, base: 21 }}
                  component="a"
                  href="https://x.com/vintsltn"
                  target="__blank"
                  aria-label="Twitter"
                >
                  <TwitterSvg />
                </Box>
              </Flex>
              <Text c="#A3A6AA" fz={{ md: "14px", base: "10px" }}>
                &#64; {currentYear} vintsolution. All Rights Reserved
              </Text>
            </Flex>
          </Grid.Col>

          <Grid.Col span={{ sm: 4, base: 6 }} order={{ xs: 2, base: 1 }}>
            <Flex
              justify={{ xs: "center", base: "start" }}
              className={classes.borderedLeft}
            >
              <Flex direction="column" gap={{ md: "12px", base: "8px" }}>
                <Text
                  fz={{ md: "18px", xs: "16px", base: "12px" }}
                  fw="600"
                  c="#FAFAFA"
                  mt={10}
                >
                  Services
                </Text>
                <ul className={classes.ulStyle}>
                  <li className={classes.footerList}>UI/UX Design</li>
                  <li className={classes.footerList}>Web development</li>
                  <li className={classes.footerList}>SEO Marketing</li>
                  <li className={classes.footerList}>Brand Identity Design</li>
                </ul>
              </Flex>
            </Flex>
          </Grid.Col>

          <Grid.Col
            span={{ sm: 4, base: 6 }}
            order={{ sm: 3, base: 2 }}
            style={{ borderLeft: "1px solid #27282A" }}
          >
            <Flex justify={{ xs: "center", base: "end" }}>
              <Flex direction="column" gap={{ md: "12px", base: "8px" }}>
                <Text
                  fz={{ md: "18px", xs: "16px", base: "12px" }}
                  fw="600"
                  c="#FAFAFA"
                  mt={10}
                >
                  Contact Us
                </Text>
                <ul className={classes.ulStyle}>
                  <li className={classes.footerList}>vintsolution@gmail.com</li>
                  <li className={classes.footerList}>+1 (647) 676 4218</li>
                </ul>
                <Box
                  mt={{ xs: 12, base: 8 }}
                  w={{ md: "175px", base: "115px" }}
                >
                  <Anchor href="#contact">
                    <CoreButton title="Hire Us" variant="primary" />
                  </Anchor>
                </Box>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
